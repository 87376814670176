<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-tabs v-model="tab" fixed-tabs show-arrows>
        <v-tab :to="`/areas/${$route.params.id}/editar`" class="ordemArea">
          Area
        </v-tab>
        <tab-rotas-box
          class="ordemBox"
          :temPermissao="
            $store.getters['usuario/temPermissao']('listar.areas.boxes')
          "
          :to="`/areas/${$route.params.id}/box`"
        />
        <tab-rotas-tabelas
          class="ordemTabela"
          :temPermissao="
            $store.getters['usuario/temPermissao']('listar.areas.tabelas')
          "
          :to="`/areas/${$route.params.id}/tabela`"
        />
        <tab-rotas-convenios
          class="ordemConvenio"
          :to="`/areas/${$route.params.id}/convenio`"
          :temPermissao="
            $store.getters['usuario/temPermissao']('listar.areas.convenios')
          "
        />
        <v-tab class="ordemPagamento"> Tipos de pagamento </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item> </v-tab-item>
        <v-tab-item> </v-tab-item>
        <v-tab-item> </v-tab-item>
        <v-tab-item> </v-tab-item>
        <v-tab-item>
          <v-card class="pt-5">
            <v-row align="baseline" justify="center">
              <v-card
                class="d-flex justify-center flex-row conteudo"
                elevation="0"
              >
                <validation-provider v-slot="{ errors }" vid="grupos">
                  <v-col class="permissoes">
                    <v-card
                      elevation="1"
                      outlined
                      class="ma-2 permissoes-caixa"
                      :disabled="$store.state.layout.loading"
                      tile
                      v-for="item in tiposPagamentos"
                      :key="item.id"
                    >
                      <v-checkbox
                        v-model="pagamentoNovo.pagamentos"
                        :label="item.descricao"
                        :disabled="$store.state.layout.loading"
                        :value="item.id"
                        :error-messages="errors"
                      ></v-checkbox>
                    </v-card>
                    <btn-salvar
                      class="py-3 botaoSalvar"
                      :temPermissao="
                        $store.getters['usuario/temPermissao'](
                          'vincular.areas.tipospagamentos'
                        )
                      "
                      :carregando="carregandoSalvar"
                      :disabled="$store.state.layout.loading"
                      @click="salvar()"
                    />
                  </v-col>
                </validation-provider>
              </v-card>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import tiposPagamentos from "@/api/tipospagamentos";
import tiposPagamentosVinculados from "@/api/areas";

export default {
  data() {
    return {
      tab: 4,
      carregandoSalvar: false,

      validacaoFormulario: {},
      tiposPagamentos: [],
      pagamentoNovo: {
        pagamentos: [],
      },
    };
  },

  mounted() {
    this.buscarTiposPagamentos();
    this.listarPermissoesGrupo();
  },

  methods: {
    async buscarTiposPagamentos() {
      try {
        const resposta = await tiposPagamentos.listar();
        this.tiposPagamentos = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      }
    },

    async listarPermissoesGrupo() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        this.pagamentoNovo.pagamentos = [];
        const resposta =
          await tiposPagamentosVinculados.listarAreasTiposPagamentos(
            this.$route.params.id
          );

        var pagamentosId = resposta.data.data;

        this.tiposPagamentos.forEach((item) => {
          pagamentosId.forEach((item2) => {
            if (item.id === item2.id) {
              this.pagamentoNovo.pagamentos.push(item.id);
            }
          });
        });
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar() {
      this.carregandoSalvar = true;

      try {
        var pagamentos = {};
        pagamentos.tipos_pagamentos = this.pagamentoNovo.pagamentos;
        console.log(pagamentos, "pagamentos");
        await tiposPagamentos.inserir(this.$route.params.id, pagamentos);

        this.$snackbar.mostrar({
          cor: "success",
          mensagem: this.$mensagens._("sucesso_adicionar"),
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
<style scope>
.permissoes {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center !important;
}

.permissoes-caixa {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px !important;
  height: 120px;
}
.botaoSalvar {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}
.conteudo {
  min-width: 100%;
}

.ordemArea {
  order: -1;
}

.ordemBox {
  order: 3;
}

.ordemTabela {
  order: 0;
}

.ordemConvenio {
  order: 3;
}

.ordemPagamento {
  order: 4;
}
</style>
